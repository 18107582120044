<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des espèces</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined class="mr-2" to="/compensationsar">
            <v-icon left dark>mdi-eye</v-icon>
            <span class="hidden-sm-and-down">Par petite région agricole</span>
        </v-btn>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="modalUpload = true" color="orange" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-cloud-upload</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Injecter / modifier des espèces</span>
        </v-tooltip>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn rounded outlined depressed text @click="downloadSpecies()" color="primary" class="mr-2 white--text" v-on="on">
                    <v-icon dark>mdi-file-excel</v-icon><span class="hidden-sm-and-down"></span>
                </v-btn>
            </template>
            <span>Télécharger la liste des espèces</span>
        </v-tooltip>
        <v-btn color="success" depressed class="white--text" to="/species/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.spg_name`]="{ item }">
            <v-chip small outlined label color="blue">{{ item.spg_name }}</v-chip>
        </template>
        <template v-slot:[`item.spe_latin_name`]="{ item }">
            <i>{{ item.spe_latin_name }}</i>
        </template>
        <template v-slot:[`item.spe_typ_id`]="{ item }">
            <v-chip small outlined label :color="getCacheType('SPE', item.spe_typ_id).typ_color">{{ getCacheType('SPE', item.spe_typ_id).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.spe_typ_id_debt`]="{ item }">
            <v-chip small outlined label :color="getCacheType('DEB', item.spe_typ_id_debt).typ_color">{{ getCacheType('DEB', item.spe_typ_id_debt).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.spe_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/species/' + item.spe_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
    <!--  Upload specie file -->
    <v-dialog v-model="modalUpload" max-width="900" >
        <v-card color="grey lighten-4" flat>
            <v-toolbar color="primary" dark class="elevation-2" height="50px">
                <v-icon class="mr-3">mdi-chevron-down</v-icon>
                <v-toolbar-title>Importer des espèces</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon color="" @click.native="modalUpload = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text>
                <v-layout row wrap>
                <v-flex xs6 class="pa-2">
                    <v-file-input ref="specie" color="orange"
                        @change="getFile($event, 'specie')" label="Selectionner un fichier"
                        v-model="form.file_specie">
                    </v-file-input>
                </v-flex>
                <v-flex xs4 class="pa-2">
                </v-flex>
                <v-flex xs2 class="pa-2">
                    <v-btn color="green" class="mt-3" @click="sendSpecieFile('specie')" :disabled="(!validUplodSpecie || saveProgress)">Envoyer</v-btn>
                </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'species',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom',                 align: 'left', value: 'spe_name'},
            { text: 'Nom latin',                 align: 'left', value: 'spe_latin_name'},
            { text: 'Groupe',              align: 'left', value: 'spg_name'},
            { text: 'Type',                align: 'left', value: 'spe_typ_id'},
            { text: 'Dette',               align: 'left', value: 'spe_typ_id_debt'},
            { text: 'Surface exigible',    align: 'right', value: 'spe_area_due'},
            { text: 'Linéaire exigible',   align: 'right', value: 'spe_linear_due'},
            { text: 'Modification',        align: 'right', value: 'spe_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            modalUpload: false,
            form: { 
                file_specie: null, file_specie_name: '', file_specie_source: '',
                ctt_typ_id: 'CTT01'
            },
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" )
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        validUplodSpecie(){
            if( this.form.file_specie_name.length > 0 ){
                return true
            } else {
                return false
            }
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('species') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/species?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        getTypeName( typ_id ){
            if( this.cacheTab.types['SPE'] !== undefined && this.cacheTab.types['SPE'][typ_id] !== undefined ){
                return this.cacheTab.types['SPE'][typ_id].typ_name 
            } else {
                return 'non défini'
            }
        },
        downloadSpecies(){
            this.$http.customRequest({ method: 'get', url: '/report/species/xlsx/?per_page=false', responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "rapport.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            })
        },
        sendSpecieFile(scope){
            // Enregistrement du formulaire
            this.saveProgress = true
            var formFile = new FormData()
            for (var prop in this.form) {
                if( prop == scope ){
                    formFile.append(prop, JSON.stringify( this.form[prop] ))
                } else {
                    formFile.append(prop, this.form[prop])
                }
            }
            if( this.form['file_' + scope] ) {
                formFile.append('file_' + scope, this.form['file_' + scope], this.form['file_' + scope].name)
            }
            this.$http.post( '/species/upload/', formFile )
            .then( (response) => {
                this.$root.$toast({ color: 'success', text: 'Données enregistrée !' })
                this.saveProgress = false
                this.modalUpload  = false
                for( let prop in response.data ){
                    if( Array.isArray( response.data[prop].error ) ){
                        this.processInfo = response.data[prop].error
                    }
                }
            }).catch( (error) => {
                this.$root.$toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                this.saveProgress = false
                this.modalUpload  = false
            })
        },
        getFile(file, scope){
            if( file !== undefined && this.form['file_' + scope + '_name'].length == 0 ){
                this.form['file_' + scope + '_name'] = file.name
            } else {
                this.form['file_' + scope + '_name'] = ''
            }
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('species', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>
<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des types</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select :items="listTypTag" @change="getTypes()" item-text="typ_tag" item-value="typ_tag" v-model="typTagFilter"  class="mr-3"
                label="Filtre" single-line >   
        </v-select>
        <v-btn color="grey" depressed class="mr-3 white--text" @click="treeView = !treeView; saveSearchToCache();">
            <v-icon left dark>mdi-tree</v-icon>
            <span class="hidden-sm-and-down">Vue en arbre</span>
        </v-btn>
        <v-btn color="success" depressed class="white--text" to="/types/0">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-if="!treeView" v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" 
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.typ_id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.typ_id }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text"></span>
            </td>
            <td class="text-xs-left">{{ item.typ_name }}</td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="primary" >{{ item.typ_entity }}</v-chip>
            </td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label :color="item.typ_color" >
                    {{ (item.typ_visibility) ? 'ACTIF' : 'INACTIF' }}
                </v-chip>
            </td>
            <td class="text-right">
                <span class="caption text--disabled font-italic">Créé le </span>
                <span class="caption text--primary font-italic">{{ locDateFormat.toLocale(item.typ_created_at, { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/types/' + item.typ_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    <v-sheet v-else-if="treeView" class="ml-4 mr-4">
        <v-treeview 
            :items="itemsTree"
            :search="search"
            item-text="typ_name" item-key="typ_id"
            hoverable activatable open-on-click> <!-- :filter="filter" :open.sync="treeViewopen"  -->
            <template v-slot:prepend="{ item }">
            <v-icon v-if="item.children">
                {{  ( !item.typ_id_parent ) ? 'mdi-home-variant' : 'mdi-folder-network' }}
            </v-icon>
            </template>
            <template v-slot:append="{ item }">
                <v-chip v-show="(item.typ_value)" class="ma-1" label color="secondary">{{ item.typ_value }}</v-chip>
                <v-btn depressed class="ml-2 rounded-xl" color="amber darken-1" :href="'/#/types/' + item.typ_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Editer
                </v-btn>
            </template>
        </v-treeview>
    </v-sheet>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'types',
    props: [],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            viewType: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Id', align: 'left', value: 'typ_id'},
            { text: 'Nom', align: 'left', value: 'typ_name'},
            { text: 'Type', value: 'typ_entity' },
            { text: 'Statut', value: 'typ_visibility' },
            { text: 'Modification', align: 'right', value: 'typ_updated_at' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            treeView: false,
            itemsTree: [],
            listTypTag: [],
            typTagFilter: ''

        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    async mounted(){
        this.search   = cacheGetters.getSearchBarItem('type') || ''
        this.viewType = cacheGetters.getSearchBarItem('typeView') || false
        this.treeView = this.viewType
        await this.getTypes()
        this.listTypTag = [...new Map(this.items.map(item => [item['typ_tag'], item])).values()]
    },
    methods: {
        getTypes(){
            return new Promise( (resolve, reject) => {
                let options = '?per_page=false&order_by=typ_id,typ_id_parent'
                options = (this.typTagFilter.length) ? options + '&typ_tag=' + this.typTagFilter : options
                this.$http.get( '/types' + options ).then( (response) => {
                        this.items = response.data.data
                        this.itemsTree = this.list_to_tree( this.items )
                        resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('type', this.search)
            cacheMutations.setSearchBarItem('typeView', this.treeView)
        },
        list_to_tree(list) {
            var map = {}, node, roots = [], i;
            
            for (i = 0; i < list.length; i += 1) {
                map[list[i].typ_id] = i; // initialize the map
                list[i].children = []; // initialize the children
            }
            
            for (i = 0; i < list.length; i += 1) {
                node = list[i];
                if (node.typ_id_parent && map[node.typ_id_parent]) {
                    // if you have dangling branches check that map[node.parentId] exists
                    list[map[node.typ_id_parent]].children.push(node);
                } else {
                    roots.push(node);
                }
            }
            return roots;
        }
    },
    components: {
        
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"item-class":_vm.tableRowStyle,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{directives:[{name:"show",rawName:"v-show",value:((_vm.title)),expression:"(title)"}]},[_c('v-alert',{staticClass:"mb-0",attrs:{"border":"left","dense":"","colored-border":"","type":"info"}},[_vm._v(_vm._s(_vm.title))])],1),_c('v-spacer'),(_vm.isAuthorized( 'Event', 'store' ))?_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","color":(_vm.routeState.view) ? 'grey' : 'green',"disabled":!_vm.routeState.edit},on:{"click":function($event){return _vm.openEvent(0)}}},[_vm._v(" Ajouter"),_c('v-icon',[_vm._v("mdi-playlist-edit")])],1):_vm._e()],1)]},proxy:true},{key:"header.eve_typ_id",fn:function(ref){
var header = ref.header;
return [(_vm.typfilter)?_c('v-menu',{staticStyle:{"z-index":"1500"},attrs:{"offset-y":"","rounded":"","close-on-content-click":false,"max-height":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":(_vm.headFilter.eve_typ_id.length) ? 'green' : 'grey'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-filter")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.getCacheType(_vm.typfilter.substring(0, 3))),function(item,index){return _c('v-list-item',{key:index,on:{"mousedown":function($event){$event.preventDefault();}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary","value":index,"hide-details":""},on:{"click":function($event){return _vm.updateHeaderFilter('eve_typ_id', item.typ_id)}}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getCacheType(_vm.typfilter.substring(0, 3), item.typ_id).typ_name))])],1)],1)}),1)],1):_vm._e(),_vm._v(" "+_vm._s(header.text)+" ")]}},{key:"item.eve_typ_id_parent",fn:function(ref){
var item = ref.item;
return [(item.eve_typ_id_parent)?_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s((_vm.typeState[item.eve_typ_id_parent.substring(0, 3)] && _vm.typeState[item.eve_typ_id_parent.substring(0, 3)][item.eve_typ_id_parent] ) ? _vm.typeState[item.eve_typ_id_parent.substring(0, 3)][item.eve_typ_id_parent].typ_name : ''))]):_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s((_vm.typeState[item.eve_typ_id.substring(0, 3)] && _vm.typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id]) ? _vm.typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_name : ''))])]}},{key:"item.eve_typ_id",fn:function(ref){
var item = ref.item;
return [(item.eve_typ_id_parent)?_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s((_vm.typeState[item.eve_typ_id.substring(0, 3)] && _vm.typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id]) ? _vm.typeState[item.eve_typ_id.substring(0, 3)][item.eve_typ_id].typ_name : ''))]):_vm._e()]}},{key:"item.doc_ids",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":(item.doc_ids) ? 'green' : 'grey',"overlap":"","offset-x":"1","bordered":"","content":(item.doc_ids) ? item.doc_ids : '0'}},[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-paperclip")])],1)]}},{key:"item.eve_sta_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","color":"green"}},[_vm._v(_vm._s(_vm.stateState['GEN'][item.eve_sta_id].sta_label))])]}},{key:"item.eve_date_start",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(_vm.locDateFormat.toLocale( item.eve_date_start )))])]}},{key:"item.eve_title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s((item.eve_title && item.eve_title.length > 2) ? item.eve_title : item.eve_description)+" ")])]}},{key:"item.eve_resolve_percent",fn:function(ref){
var item = ref.item;
return [(item.eve_typ_id_rec == 'REC01')?_c('div',[(!(_vm.typfilter == 'CTR'))?_c('v-simple-checkbox',{attrs:{"value":(item.eve_resolve_percent) ? true : false,"disabled":""}}):_c('span',[_vm._v(_vm._s(item.eve_resolve_percent)+" %")])],1):_c('div',[_c('v-chip',{attrs:{"title":"Evènement virtuel","label":"","outlined":"","color":"orange"}},[_c('v-icon',[_vm._v("mdi-calendar-multiselect")])],1)],1)]}},{key:"item.eve_id",fn:function(ref){
var item = ref.item;
return [(_vm.isAuthorized( 'Event', 'update' ))?_c('v-chip',{attrs:{"color":"grey","label":"","outlined":""},on:{"click":function($event){_vm.virutalEvent = item; _vm.openEvent(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_c('v-chip',{attrs:{"color":"grey","label":"","outlined":""},on:{"click":function($event){return _vm.openEvent(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.eve_custom_fields",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey","label":"","outlined":""}},[_vm._v(_vm._s((item.eve_custom_fields && item.eve_custom_fields.tcv_code) ? item.eve_custom_fields.tcv_code : ''))])]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":( !_vm.loading.getEvent ),"color":"info","icon":"mdi-information-outline"}},[_vm._v(" Aucun événement ")])]},proxy:true}],null,true)}),_c('v-dialog',{staticStyle:{"z-index":"1056"},attrs:{"max-width":"900"},model:{value:(_vm.modalEvent),callback:function ($$v) {_vm.modalEvent=$$v},expression:"modalEvent"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1),_c('v-toolbar-title',[_vm._v("Évènement")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){return _vm.switchModal('modalEvent', 0)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pa-2"},[(_vm.modalEvent)?_c('edit-event',{staticClass:"elevation-0",attrs:{"switchModal":_vm.switchModal,"virutalEvent":_vm.virutalEvent,"xxxId":_vm.eveInfo.eve_xxx_id,"eveId":_vm.eveInfo.eve_id,"eveTable":(_vm.table_id) || 'trs',"eveType":(_vm.typfilter) ? _vm.typfilter.substring(0, 3) : _vm.typfilter,"sheetType":(_vm.sheetType) ? _vm.sheetType : false}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
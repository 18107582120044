var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"elevation-1",attrs:{"height":"48px"}},[_c('v-toolbar-title',[_vm._v("Gestion des espèces")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","outlined":"","to":"/compensationsar"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-eye")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Par petite région agricole")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 white--text",attrs:{"rounded":"","outlined":"","depressed":"","text":"","color":"orange"},on:{"click":function($event){_vm.modalUpload = true}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cloud-upload")]),_c('span',{staticClass:"hidden-sm-and-down"})],1)]}}])},[_c('span',[_vm._v("Injecter / modifier des espèces")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 white--text",attrs:{"rounded":"","outlined":"","depressed":"","text":"","color":"primary"},on:{"click":function($event){return _vm.downloadSpecies()}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-file-excel")]),_c('span',{staticClass:"hidden-sm-and-down"})],1)]}}])},[_c('span',[_vm._v("Télécharger la liste des espèces")])]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"success","depressed":"","to":"/species/0"}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("Créer")])],1)],1),_c('v-text-field',{staticClass:"ma-4 pa-0",attrs:{"append-icon":"mdi-magnify","label":"Recherche","solo":"","clearable":"","single-line":"","hide-details":""},on:{"blur":function($event){return _vm.saveSearchToCache()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{staticClass:"ma-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"sta_id","search":_vm.search,"items-per-page-text":"Lignes par page","items-per-page-options":_vm.pagination.rowsPerItem},scopedSlots:_vm._u([{key:"item.spg_name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":"blue"}},[_vm._v(_vm._s(item.spg_name))])]}},{key:"item.spe_latin_name",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(item.spe_latin_name))])]}},{key:"item.spe_typ_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheType('SPE', item.spe_typ_id).typ_color}},[_vm._v(_vm._s(_vm.getCacheType('SPE', item.spe_typ_id).typ_name))])]}},{key:"item.spe_typ_id_debt",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","outlined":"","label":"","color":_vm.getCacheType('DEB', item.spe_typ_id_debt).typ_color}},[_vm._v(_vm._s(_vm.getCacheType('DEB', item.spe_typ_id_debt).typ_name))])]}},{key:"item.spe_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-2 elevation-1",attrs:{"outlined":"","small":"","color":"grey","href":'/#/species/' + item.spe_id + ''}},[_c('v-icon',{staticClass:"mr-2",attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("Modifier ")],1)]}},{key:"pageText",fn:function(ref){
var pageStart = ref.pageStart;
var pageStop = ref.pageStop;
return [_vm._v(" De "+_vm._s(pageStart)+" à "+_vm._s(pageStop)+" ")]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.modalUpload),callback:function ($$v) {_vm.modalUpload=$$v},expression:"modalUpload"}},[_c('v-card',{attrs:{"color":"grey lighten-4","flat":""}},[_c('v-toolbar',{staticClass:"elevation-2",attrs:{"color":"primary","dark":"","height":"50px"}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-chevron-down")]),_c('v-toolbar-title',[_vm._v("Importer des espèces")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":""},nativeOn:{"click":function($event){_vm.modalUpload = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pa-2",attrs:{"xs6":""}},[_c('v-file-input',{ref:"specie",attrs:{"color":"orange","label":"Selectionner un fichier"},on:{"change":function($event){return _vm.getFile($event, 'specie')}},model:{value:(_vm.form.file_specie),callback:function ($$v) {_vm.$set(_vm.form, "file_specie", $$v)},expression:"form.file_specie"}})],1),_c('v-flex',{staticClass:"pa-2",attrs:{"xs4":""}}),_c('v-flex',{staticClass:"pa-2",attrs:{"xs2":""}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"green","disabled":(!_vm.validUplodSpecie || _vm.saveProgress)},on:{"click":function($event){return _vm.sendSpecieFile('specie')}}},[_vm._v("Envoyer")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }